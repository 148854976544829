import React from "react";
// reactstrap components
import { Button, Modal, Card, CardBody, CardTitle, CardFooter } from "reactstrap";

function ButtonInfoModal(props) {
  const [noticeModal, setNoticeModal] = React.useState(false);
  const { nombre, apellido, tipo, email, ubicacion, telefono, superficie, inmobiliaria, mensaje } = props
  return(
    <>
      <Button
        className="btn-link mr-1"
        color="info" 
        size="sm"
        type="button"
        onClick={() => setNoticeModal(true)}
      >
        <i className="nc-icon x2 icon-eye-2"></i>
      </Button>
      <Modal isOpen={noticeModal} toggle={() => setNoticeModal(false)}>
        <div className="modal-header py-0 no-border-header">
          <h5 className="modal-title" id="myModalLabel">
            Detalles
          </h5>
          <button
            className="close"
            onClick={() => setNoticeModal(false)}
            type="button"
          >
            ×
          </button>
        </div>
        <div className="modal-body py-0">
        <Card className="card-profile card-plain mt-0">
          <CardBody>
            <CardTitle tag="h5">De: <strong>{nombre} {apellido}</strong></CardTitle>
            <CardTitle tag="h5">Tipo: <strong>{tipo}</strong></CardTitle>
            <CardTitle tag="h5">Mail: <strong>{email}</strong></CardTitle>
            {ubicacion && 
              <CardTitle tag="h5">Ubicación: <strong>{ubicacion}</strong></CardTitle>
            }
            {superficie && 
              <CardTitle tag="h5">Superficie: <strong>{superficie}</strong></CardTitle>
            }
            {telefono && 
              <CardTitle tag="h5">Telefono: <strong>{telefono}</strong></CardTitle>
            }
            {inmobiliaria && 
              <CardTitle tag="h5">Inmobiliaria: <strong>{inmobiliaria}</strong></CardTitle>
            }
            {mensaje && 
              <p className="card-description">
                <strong>Mensaje: </strong>{mensaje}
              </p>
            }
            
          </CardBody>
        <CardFooter className="text-center">
          <Button
            className="btn-icon btn-border btn-round"
            color="default"
            href="" 
            onClick={e => e.preventDefault()}
            outline
          >
            <i className="nc-icon icon-phone pr-2"></i>
                Llamar
          </Button>
          <Button
            className="btn-icon btn-border btn-round"
            color="default"
            href="#pablo"
            onClick={e => e.preventDefault()}
            outline
          >
            <i className="nc-icon icon-mail pr-2"></i>
                Responder
          </Button>
        </CardFooter>
      </Card>
          </div>
        <div className="modal-footer">
          <Button
            className="btn-link"
            color="primary"
            type="button"
            onClick={() => setNoticeModal(false)}
          >
            Listo
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ButtonInfoModal;


