import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../assets/img/logo.png'
// JavaScript plugin that hides or shows a component based on your scroll
import { Event } from '../../components/Tracking'
import Headroom from "headroom.js";
// reactstrap components
import { Button, Collapse, NavbarBrand, Navbar, NavItem, Nav, NavLink, Container } from "reactstrap";
// core components

function PrincipalNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/#home" tag={Link}>
              <img src={Logo} alt="logo"/>lanube360
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink 
                  to="/#nosotros" 
                  tag={Link}
                  onClick={ () => Event("navbar", "clic button nosotros", "nosotros")}
                >
                  Nosotros
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink 
                  to="/#servicios" 
                  tag={Link}
                  onClick={ () => Event("navbar", "clic button servicios", "servicios")}
                >
                  Servicios
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink 
                  to="/#portafolio" 
                  tag={Link}
                  onClick={ () => Event("navbar", "clic button portafolio", "portafolio")}
                >
                  Portafolio
                </NavLink>
              </NavItem>    
              <NavItem>
                <Button
                  className="btn-round"
                  color="danger"
                  to="/#contact-us"
                  tag={Link}
                  onClick={ () => Event("navbar", "clic button contacto", "contacto")}
                >
                  Contacto
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default PrincipalNavbar;
