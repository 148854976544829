import React from "react";
import { Event } from '../Tracking'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Formulario from '../Formulario'
const SectionContacto = () => {
  return (
      <div className="section section-contactus cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/pk-pro-cover.jpg") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Contactanos
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div className="info-contacto info-horizontal">
                          <a onClick={() => Event('metodo Contacto','clic llamada','telefono')} href="tel:+56992182984">
                            <div className="icon icon-info">
                              <i className="nc-icon icon-phone" />
                            </div>
                          </a>
                          <div className="description">
                            <h4 className="info-title">
                              Llamanos
                            </h4>
                            <p>
                              <a onClick={() => Event('metodo Contacto','clic llamada','telefono')} href="tel:+56992182984">+56 9 92182984</a>,<br />
                              Santiago, <br />
                              Puerto Varas
                            </p>
                          </div>
                        </div>
                        <div className="info-contacto info-horizontal">
                          <a onClick={() => Event('metodo Contacto','clic mail','mail')} href="mailto:info@lanube360.cl">
                            <div className="icon icon-danger">
                              <i className="nc-icon icon-mail" />
                            </div>
                          </a>
                          <div className="description">
                            <h4 className="info-title">Escribenos</h4>
                            <p>
                              <a onClick={() => Event('metodo Contacto','clic mail','mail')} href="mailto:info@lanube360.cl">info@lanube360.cl</a><br />
                            </p>
                          </div>
                        </div>
                        <div className="info-contacto info-horizontal">
                          <a onClick={() => Event('metodo Contacto','clic whatsapp','whatsapp')} target="blank" href="https://api.whatsapp.com/send?phone=56992182984">
                            <div className="icon icon-success">
                              <i className="nc-icon icon-logo-whatsapp" />
                            </div>
                          </a>
                          <div className="description">
                            <h4 className="info-title">WhatsApp</h4>
                            <p>Comunicate!</p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Formulario/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
  )
}

export default SectionContacto
