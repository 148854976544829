import React from 'react'
import { BrowserRouter, Route } from "react-router-dom"
import { withAuthentication } from '../Session'
import SignInPage from '../../Pages/SignInPage'
import AdminPage from '../../Pages/AdminPage'
import HomePage from '../../Pages/HomePage'
const App = () => {
    return(
        <BrowserRouter>
            <div>
                <Route path="/" exact component={HomePage} />
                <Route path="/signin" component={SignInPage} />
                <Route path="/admin" component={AdminPage} />
            </div>
        </BrowserRouter>
    )
}
export default withAuthentication(App)