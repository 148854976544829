import React from "react";
import ReactDOM from "react-dom";
import Firebase, { FirebaseContext } from './components/Firebase'
import App from './components/App'
// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import 'assets/css/lanube360.css'
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages

ReactDOM.render(
  <FirebaseContext.Provider value = {new Firebase()}>
    <App/>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
