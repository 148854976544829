import React, { Component } from 'react'
import { withFirebase } from '../Firebase'
import { Modal, Button } from 'reactstrap'

class ButtonAvisoModalBase extends Component{
    constructor(props){
        super(props)
        this.state={
            isVisibleAlert:false,
        }
    }
    onVisibleAlert = () => {
        const isVisibleAlert = !this.state.isVisibleAlert
        this.setState({isVisibleAlert})
    }
    onBorrarCotizacion = () => {
        const { cotizacion } = this.props
        this.props.firebase.cotizacion(cotizacion.uid).delete()
    }
    onBorrarProyecto = () => {
        const { proyecto } = this.props
        this.props.firebase.proyecto(proyecto.uid).delete()
        this.props.firebase.borrarSpots(proyecto.numSpots, proyecto.nombreProyecto)
    }  
    render(){
        const { className, color, size, type, children, outline, proyecto, cotizacion} = this.props
        const { isVisibleAlert } = this.state
        return(
            <>
                <Button
                    className={className}
                    color={color}
                    size={size}
                    type={type}
                    onClick={this.onVisibleAlert}
                    outline={outline}
                >
                    {children}
                </Button>
                <Modal
                    size="sm"
                    isOpen={isVisibleAlert}
                    toggle={this.onVisibleAlert}
                >
                    <div className="modal-body text-center">
                        <h5>Confirmar borrado</h5>
                    </div>
                    <div className="modal-footer">
                        <div className="left-side">
                            <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={this.onVisibleAlert}
                            >
                            Cancelar
                            </Button>
                        </div>
                    <div className="divider" />
                        <div className="right-side">
                            {proyecto && <Button
                                className="btn-link"
                                color="danger"
                                type="button"
                                onClick = {this.onBorrarProyecto}
                            >
                                Borrar
                            </Button>

                            }
                            {cotizacion && 
                                <Button
                                className="btn-link"
                                color="danger"
                                type="button"
                                onClick = {this.onBorrarCotizacion}
                                >
                                Borrar
                                </Button>
                            }
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
    
}
const ButtonAvisoModal = withFirebase(ButtonAvisoModalBase)
export default ButtonAvisoModal