import React from "react";
import { PageView, initGA } from '../../components/Tracking'
// core components
import PrincipalNavbar from "../../components/PrincipalNavbar"
import SectionHeader from "../../components/SectionHeader";
import SectionNosotros from '../../components/SectionNosotros'
import SectionServicios from '../../components/SectionServicios'
import SectionPortafolio from '../../components/SectionPortafolio'
import SectionContacto from '../../components/SectionContacto'

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  initGA('UA-85354266-1')
  PageView()
  React.useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });
  return (
    <>
      <PrincipalNavbar />
      <SectionHeader />
      <SectionNosotros />
      <SectionServicios/>
      <SectionPortafolio/>
      <SectionContacto />
    </>
  );
}

export default HomePage