import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { withAuthorization } from '../../components/Session'
import { withFirebase } from '../../components/Firebase'
import { Button, Table, Container, Row, Col } from "reactstrap"
import Proyectos from '../../components/Proyectos'
import Proyecto from '../../components/Proyecto'
import NavigationAdmin from '../../components/NavigationAdmin'
import ButtonInfoModal from '../../components/ButtonInfoModal'
import ButtonAvisoModal from '../../components/ButtonAvisoModal'
import MiCuenta from '../../components/MiCuenta'
import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'

const AdminPage = () => {
    return(
      <div>
        <NavigationAdmin/>
        <Switch>
          <Route exact path='/admin/proyectos' component={ Proyectos }/>
          <Route path='/admin/cotizaciones' component={ SectionTables }/>
          <Route path='/admin/micuenta' component={ MiCuenta}/>
          <Route path={ROUTES.PROYECTO_DETAILS} component={ Proyecto }/>
        </Switch>

      </div>
    )
}
const condition = authUser => authUser && (authUser.roles[ROLES.ADMIN] || authUser.roles[ROLES.EDITOR] || authUser.roles[ROLES.USER])
export default withAuthorization(condition)(AdminPage)

class SectionTablesBase extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      cotizaciones:[],
      limit:5,
      loading:false
    }
  }
  fetchCotizaciones = () => {
    this.setState({loading: true})
    this.unsubscribe = this.props.firebase.cotizaciones()
      .orderBy('createdAt','desc')
      .limit(this.state.limit)
      .onSnapshot( snapshot => {
        if(snapshot.size){
          let cotizaciones = []
          snapshot.forEach( doc => 
            cotizaciones.push({ ...doc.data(), uid: doc.id })
          )
          this.setState({
            cotizaciones: cotizaciones,
            loading:false
          })

        }else {
          this.setState({ cotizaciones: null, loading:false})
        }
      })
  }
  onNextPage = () => {
    this.setState(
      state => ({limit: state.limit + 5}),
      this.fetchCotizaciones
    )
  }
  componentDidMount(){
    this.fetchCotizaciones()
  }
  componentWillUnmount(){
    this.unsubscribe()
  }
  render(){
    const { cotizaciones, loading } = this.state
  
    return (
      <div className="section section-gray">
        <Container className="tim-container">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h4 className="title">
                <small>Formulario Contacto</small>
              </h4>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Nombre</th>
                      <th>Tipo</th>
                      <th>Fecha</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cotizaciones? (cotizaciones.map( (cotizacion,i) => {
                      const fecha = cotizacion.createdAt.toDate().toLocaleString('cl-CL')
                      return(
                          <tr className="" key={cotizacion.uid}>
                            <td className="text-center">{i}</td>
                            <td>{cotizacion.nombre}</td>
                            <td>{cotizacion.tipo}</td>
                            <td>{fecha}</td>
                            <td className="td-actions text-center">
                              <ButtonInfoModal {...cotizacion}/>
                              <ButtonAvisoModal cotizacion={cotizacion} className="btn-link mr-1" color="info" size="sm" type="button">
                                <i className="nc-icon x2 icon-t-remove" />
                              </ButtonAvisoModal>
                            </td>
                          </tr>
                          
                        )})
                        ):(<tr>
                              <td>
                                No hay cotizaciones
                              </td>
                            </tr>
                          )
                    }
                  </tbody>
                </Table>
              {!loading && cotizaciones && (
                <Button type="button" onClick={this.onNextPage}>
                  More
                </Button>
                )
              }
            </Col>
          </Row>
        </Container> 
      </div>
    )
  }
}
const SectionTables = withFirebase(SectionTablesBase)


