import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Col, Row, Button, Form, FormGroup, Input, Modal, Label } from 'reactstrap'
import { withFirebase } from '../../components/Firebase'
import * as ROLES from '../../constants/roles.js' 
import * as ROUTES from '../../constants/routes.js'


const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'
const ERROR_MSG_ACCOUNT_EXISTS = `Ya existe una cuenta con esta dirección de correo electrónico.`

const INITIAL_STATE = {
    username:'',
    email:'',
    passwordOne:'',
    passwordTwo:'',
    nuevoUsuario:false,
    opcionSeleccionada:'usuario',
    loading:false,
    error:null
}
class ModalNuevoUsuarioBase extends Component{
    constructor(props){
        super(props)
        this.state = {...INITIAL_STATE}
    }
    onSubmit = event => {
        const { username, email, passwordOne, opcionSeleccionada } = this.state
        this.setState({loading:true})
        const roles = {}
        if( opcionSeleccionada === 'admin') {
            roles[ROLES.ADMIN] = ROLES.ADMIN
        }
        if( opcionSeleccionada === 'usuario'){
            roles[ROLES.USER] = ROLES.USER
        }
        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then( authUser => {
                //crea un usuario en la base de datos
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set(
                        { username, email, roles },
                        {  merge: true },
                    )
            })
            // .then(() => {
            //     return this.props.firebase.doSendEmailVerification();
            // })
            .then( () => {
                this.setState({ ...INITIAL_STATE })
                this.props.history.push(ROUTES.PROYECTOS)
            })
            .catch(error => {
                if(error.code === ERROR_CODE_ACCOUNT_EXISTS){
                    error.message = ERROR_MSG_ACCOUNT_EXISTS
                }
                this.setState({ error, loading:false })
            })
            
        event.preventDefault()
       
    }
    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    onChangeOption = event => {
        this.setState({ opcionSeleccionada: event.target.value })
    }
    render(){
        const { username, email, passwordOne, passwordTwo, nuevoUsuario, loading, error} = this.state
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '' || username === ''
        return(
            <>
                <Button
                    className="btn-round"
                    color="primary"
                    type="button"
                    onClick={() => this.setState({nuevoUsuario:true})}
                    >
                    Nuevo Usuario
                </Button>
                <Modal
                    isOpen={nuevoUsuario}
                    toggle={() => this.setState({...INITIAL_STATE})}
                    className="modal-register"
                >
                    <div className="modal-header no-border-header text-center">
                        <button
                            className="close"
                            type="button"
                            onClick={() => this.setState({nuevoUsuario:false})}
                        >
                            <span>×</span>
                        </button>
                        <h3 className="modal-title text-center">Nueva Cuenta Usuario</h3>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <label>Nombre</label>
                                <Input 
                                    name="username"
                                    value={username}
                                    onChange={this.onChange}
                                    placeholder="Nombre" 
                                    type="text"
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Email</label>
                                <Input 
                                    name="email"
                                    value={email}
                                    onChange={this.onChange}
                                    placeholder="Email" 
                                    type="text" 
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Password</label>
                                <Input
                                    name="passwordOne"
                                    value={passwordOne}
                                    onChange={this.onChange}
                                    placeholder="Password"
                                    type="password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Confirma Password</label>
                                <Input
                                    name="passwordTwo"
                                    value={passwordTwo}
                                    onChange={this.onChange}
                                    placeholder="Confirma tu password"
                                    type="password"
                                />
                            </FormGroup>
                            <Row className="pb-3">
                                <Col md="6">
                                    <div className="form-check-radio">
                                        <Label check>
                                            <Input
                                                name="isAdmin"
                                                value="admin"
                                                onChange={this.onChangeOption}
                                                checked={this.state.opcionSeleccionada === 'admin'}
                                                type="radio"
                                            />
                                            Admin<span className="form-check-sign" />
                                        </Label>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-check-radio">
                                        <Label check>
                                            <Input
                                                name="isUsuario"
                                                value="usuario"
                                                onChange={this.onChangeOption}
                                                checked={this.state.opcionSeleccionada === 'usuario'}
                                                type="radio"
                                            />
                                            Usuario<span className="form-check-sign" />
                                        </Label> 
                                    </div>
                                </Col>
                            </Row>
                            <Button disabled={isInvalid} type="submit" block className="btn-round" color="default">
                                Registrar
                            </Button>
                            <Row>
                                <Col className="text-center pt-3">
                                    {loading && <div className="uil-reload-css reload-small block"><div/></div>}
                                    {error && <p> {error.message} </p>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                  </Modal>
            </>
        )
    }
}
const ModalNuevoUsuario = compose(withRouter, withFirebase)(ModalNuevoUsuarioBase)
export default ModalNuevoUsuario