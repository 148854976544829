import React ,{ useState } from 'react'
import { Form, FormGroup, Input, Button, Modal } from 'reactstrap'
import { withFirebase } from 'components/Firebase'
import { Event } from '../Tracking'

const ModalMasterplanBase = props => {
    
    const [loading, setLoading] = useState(false)
    const [masterModal, setMasterModal] = useState(false)
    const [textNombre, setTextNombre] = useState('')
    const [textEmail, setTextEmail] = useState('')
    const [textUbicacion, setTextUbicacion] = useState('')
    const [textSuperficie, setTextSuperficie] = useState('')
    const [textTelefono, setTextTelefono] = useState('')

    const handleChangeTextNombre = event => {
        setTextNombre(event.target.value)
    }
    const handleChangeTextEmail = event => {
        setTextEmail(event.target.value)
    }
    const handleChangeTextUbicacion = event => {
        setTextUbicacion(event.target.value)
    }
    const handleChangeTextSuperficie = event => {
        setTextSuperficie(event.target.value)
    }
    const handleChangeTextTelefono = event => {
        setTextTelefono(event.target.value)
    }
    const isInvalid = textEmail === '' || textUbicacion === '' || textSuperficie === '' 

    const handleSubmit = event => {
        event.preventDefault()
        setLoading(true)
        props.firebase
            .cotizaciones()
            .add({
                nombre: textNombre,
                email: textEmail,
                telefono: textTelefono,
                ubicacion: textUbicacion,
                superficie: textSuperficie,
                tipo:"Masterplan",
                createdAt: props.firebase.fieldValue.serverTimestamp()
            })
            .then(resp => {
                if(!loading){
                    setMasterModal(false)
                }
            }) 
            .catch( err => console.log('ha ocurrido un error:', err))   
            setLoading(false)
            setTextNombre('')
            setTextEmail('')
            setTextTelefono('')
            setTextSuperficie('')
            setTextUbicacion('')
    }
    return(
        <div>
            <Button 
                className="btn-link" 
                color="info" 
                onClick={() => { 
                    setMasterModal(true);
                    Event("Cotizacion", "Clic abre modal cotizar", "Masterplan"); 
                }}
            >
                Cotizar
            </Button>
            <Modal
                isOpen={masterModal}
                toggle={() => setMasterModal(false)}
                modalClassName="modal-register"
                onClick={() => Event("Cotizacion", "clic cierra modal cotizar", "Masterplan")}
            >
                <div className="modal-header no-border-header text-center">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            setMasterModal(false)
                            Event("Cotizacion", "clic cierra modal cotizar", "Masterplan")
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h3 className="modal-title text-center">Master Plan 360</h3>
                </div>
                <div className="modal-body">
                    <Form onSubmit={ handleSubmit }>
                        <FormGroup>
                            <label>Nombre</label>
                            <Input
                                name="textnombre"
                                value={textNombre}
                                onChange={handleChangeTextNombre}
                                placeholder="Tu Nombre" 
                                type="text" 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Email</label>
                            <Input
                                name="textEmail"
                                value={textEmail}
                                onChange={handleChangeTextEmail}
                                placeholder="Tu Email" 
                                type="email" 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Telefono</label>
                            <Input
                                name="textTelefono"
                                value={textTelefono}
                                onChange={handleChangeTextTelefono}
                                placeholder="Tu telefono"
                                type="text" 
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Ubicación Proyecto</label>
                            <Input 
                                name="textUbicacion"
                                value={textUbicacion}
                                onChange={handleChangeTextUbicacion}
                                placeholder="¿Donde esta ubicado?" type="text" />
                        </FormGroup>
                        <FormGroup>
                            <label>Superficie Total Proyecto</label>
                            <Input
                                name="textSuperficie"
                                value={textSuperficie}
                                onChange={handleChangeTextSuperficie}
                                placeholder="Superficie en hectareas" 
                                type="text" 
                            />
                        </FormGroup>
                        <Button 
                            disabled = {isInvalid}
                            className="btn-round" 
                            color="default"
                            type="submit"
                            onClick={ () => Event("Cotizacion", "clic cotiza", "Masterplan")}
                        >
                            Cotiza
                        </Button>
                        {loading && (<p>Enviando...</p>)}
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
const ModalMasterplan = withFirebase(ModalMasterplanBase)
export default ModalMasterplan