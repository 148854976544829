import React, { Component } from 'react'
import { Form, Col, Row, Input, FormGroup, CardBody, Label, Button } from 'reactstrap'
import { withFirebase } from '../../components/Firebase'

class FormularioBase extends Component {
    constructor(props){
        super(props)
        this.state={
            loading:false,
            textNombre:'',
            textApellido:'',
            textEmail:'',
            textMensaje:''
        }
    }
    onChangeText = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    // onChangeCheckbox = event => {
    //     this.setState({ [event.target.name] : event.target.checked })
    // }
    onEnviarFormulario = (event) => {
        const { textNombre, textApellido, textEmail, textMensaje } = this.state
        this.setState({loading:true})
        event.preventDefault()
        this.props.firebase
            .cotizaciones()
            .add({
                nombre: textNombre,
                apellido: textApellido,
                tipo:"Contacto",
                email: textEmail,
                mensaje: textMensaje,
                createdAt: this.props.firebase.fieldValue.serverTimestamp()
            })
            .then(docRef => {
                this.setState({loading:false})
            })  
        this.setState({ 
            textNombre: '',
            textApellido:'',
            textEmail:'',
            textMensaje:''
        })
    }
    render(){
        const { loading, textNombre, textApellido, textEmail, textMensaje} = this.state
        const isInvalid = textNombre === '' || textApellido === '' || textEmail ==='' || textMensaje === ''
        return(
            <Form onSubmit={this.onEnviarFormulario}>
                <CardBody>
                    <Row>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Nombre
                                </label>
                                <Input
                                    name="textNombre"
                                    value={textNombre}
                                    onChange={this.onChangeText}
                                    placeholder="Tu Nombre"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Apellido
                                </label>
                                <Input
                                    name="textApellido"
                                    value={textApellido}
                                    onChange={this.onChangeText}
                                    placeholder="Tu Apellido"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Email 
                        </label>
                        <Input
                            name="textEmail"
                            value={textEmail}
                            onChange={this.onChangeText}
                            placeholder=" Tu Email"
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Mensaje
                        </label>
                        <Input
                            id="message"
                            name="textMensaje"
                            value={textMensaje}
                            onChange={this.onChangeText}
                            placeholder="Tu mensaje"
                            type="textarea"
                            rows="6"
                        />
                    </FormGroup>
                    <Row>
                        <Col md="6">
                            <FormGroup check>
                                <Label check>
                                    <Input defaultValue="" type="checkbox" />
                                    No soy un robot!{" "}
                                    <span className="form-check-sign" />
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <Button type ="submit" color="primary" disabled={isInvalid}>
                                {loading && 
                                    <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                    </div>
                                }
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Form>
        )
    }
}
const Formulario = withFirebase(FormularioBase)
export default Formulario