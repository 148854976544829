import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose' 
import { withFirebase } from '../Firebase'
import Select from 'react-select'
import { Modal, Form, FormGroup, Input, Button, Label, Row, Col } from 'reactstrap'

import * as ROUTES from '../../constants/routes.js'
const INITIAL_STATE = {
    nuevoProyecto:false,
    textNombreProyecto:'',
    selectedOption: null,
    numSpots:'',
    isTitulo: true,
    isEstado: true,
    isSuperficie:false,
    isValor:false,
    isServidumbre:false,
    isCaracteristicas:false,
    loading: false,
    error:null
}
class ModalFormProyectoBase extends Component {
    constructor(props){
        super(props)
        this.state={
            ...INITIAL_STATE,
            options:[]
        }
    }
    onChangeText = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    onChangeSelect = value => this.setState({ selectedOption : value})
    onChangeCheckbox = event => {
        this.setState({ [event.target.name] : event.target.checked })
    }
    onCreateProyecto = (event) => {
        const { selectedOption, textNombreProyecto, numSpots, isTitulo, isEstado, isSuperficie, isValor, isServidumbre, isCaracteristicas } = this.state
        const { email } = this.props.firebase.auth.currentUser
        const numeroSpots = parseInt(numSpots)
        const nombreProyecto = textNombreProyecto.replace(/ /g,'-').toLowerCase()
        this.setState({loading:true})
        this.props.firebase
            .proyecto(nombreProyecto)
            .set({
                nombreProyecto: textNombreProyecto,
                usuarioAsociado: selectedOption,
                creadoPor: email, 
                numSpots,
                isTitulo,
                isEstado,
                isSuperficie,
                isValor,
                isServidumbre,
                isCaracteristicas,
                createdAt: this.props.firebase.fieldValue.serverTimestamp()
                },
                { merge:true }
            ).then(() => this.props.firebase.crearSpots(numeroSpots,nombreProyecto))
            .then(() => {
                this.props.history.push(ROUTES.PROYECTOS)
                this.setState({...INITIAL_STATE})
            })
            .catch(error => this.setState({error, loading:false}))
        event.preventDefault()
    }
    fetchProyecto(){
        const { nombreProyecto, usuarioAsociado, numSpots, isTitulo, isEstado, isSuperficie, isServidumbre, isCaracteristicas } = this.props.proyecto
        this.setState({
            textNombreProyecto: nombreProyecto,
            selectedOption: usuarioAsociado,
            numSpots,
            isTitulo,
            isEstado,
            isSuperficie,
            isServidumbre,
            isCaracteristicas
        })
    }
    componentDidMount(){
        if(this.props.proyecto){
            this.fetchProyecto()
        }
        this.unsubscribe= this.props.firebase
            .users()
            .onSnapshot(snapshot =>{
                if(snapshot.size){
                    let listaUsuarios = [{value:"",label:"Elije una cuenta", isDisabled:true}]
                    snapshot.forEach(doc => 
                    listaUsuarios.push({ label:doc.data().username, uid: doc.id })
                    )
                    this.setState({
                        options: listaUsuarios
                    })
                } 
            })
    }
    componentWillUnmount(){
        this.unsubscribe()
    }
    render(){
        const { className, color, size, type, outline, titleForm, children } = this.props
        const { nuevoProyecto, textNombreProyecto, textNombreEditor, numSpots, isTitulo, isEstado, isSuperficie, isValor, isServidumbre, isCaracteristicas, loading, error, selectedOption, options} = this.state
        const isInvalid = textNombreProyecto === '' || textNombreEditor === '' || numSpots === ''
        return(
            <>
                <Button
                    className={className}
                    color={color}
                    size={size}
                    type={type}
                    outline={outline}
                    onClick={() => this.setState({nuevoProyecto:true})}
                    >
                   {children}
                </Button>
                <Modal
                    isOpen={nuevoProyecto}
                    toggle={() => this.setState({nuevoProyecto:false})}
                    className="modal-register"
                >
                    <div className="modal-header no-border-header text-center">
                        <button
                            className="close"
                            type="button"
                            onClick={() => this.setState({nuevoProyecto:false})}
                        >
                            <span>×</span>
                        </button>
                        <h3 className="modal-title text-center">{titleForm}</h3>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={this.onCreateProyecto}>
                            <FormGroup>
                                <label>Asocia Cuenta</label>
                                <Select
                                    className="react-select react-select-default"
                                    classNamePrefix="react-select"
                                    value={selectedOption}
                                    onChange={this.onChangeSelect}
                                    options={options}
                                    isSearchable={false}
                                    placeholder="Elije una cuenta"
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Nombre Proyecto</label>
                                <Input  
                                    name="textNombreProyecto" 
                                    type="text" 
                                    value={textNombreProyecto} 
                                    onChange={this.onChangeText} 
                                    placeholder='Nombre Proyecto' 
                                />
                            </FormGroup>
                            <Row className="pb-3">
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isTitulo"
                                            type="checkbox"
                                            checked={isTitulo}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Titulo <span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isEstado"
                                            type="checkbox"
                                            checked={isEstado}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Estado<span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isSuperficie"
                                            type="checkbox"
                                            checked={isSuperficie}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Superficie<span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isValor"
                                            type="checkbox"
                                            checked={isValor}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Valor<span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isServidumbre"
                                            type="checkbox"
                                            checked={isServidumbre}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Servidumbre<span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check>
                                        <Input 
                                            name="isCaracteristicas"
                                            type="checkbox"
                                            checked={isCaracteristicas}
                                            onChange={this.onChangeCheckbox}
                                        />
                                            Caracteristicas<span className="form-check-sign" />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <FormGroup>
                                    <label>Spots</label>
                                    <Input 
                                        name="numSpots"
                                        type="text"
                                        value={numSpots}
                                        onChange={this.onChangeText}
                                    />
                                </FormGroup>
                            </Row>
                            <Button disabled={isInvalid} type="submit" block className="btn-round" color="default">
                                Registrar
                            </Button>
                            <Row>
                                <Col className="text-center pt-3">
                                    {loading && <div className="uil-reload-css reload-small block"><div/></div>}
                                    {error && <p> {error.message} </p>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                  </Modal>
            </>
        )
    }
}
// const condition = authUser => authUser && !! authUser.roles[ROLES.ADMIN]
const ModalFormProyecto = compose(withRouter,withFirebase)(ModalFormProyectoBase)
export default ModalFormProyecto