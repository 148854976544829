import React, { useState }from 'react'
import classnames from 'classnames'
import { Container, Row, Col, Card, CardImg, CardText, CardBody,CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import list from './list.js'
const SectionPortafolio = () => {
    const [ activeTab, setActiveTab ] = useState('master') 
    const toggle = tab => {
        if(activeTab !== tab){
            setActiveTab(tab)
        }
    }
    return(
    <div className="section" id="portafolio">
        <Container>
            <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                    <Nav role="tablist" tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'master' })}
                                onClick={() => { toggle('master'); }}
                            >
                            MasterPlan 360º
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'tour-aereo' })}
                                onClick={() => { toggle('tour-aereo'); }}
                            >
                            Tour Aéreo
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'piloto-virtual' })}
                                onClick={() => { toggle('piloto-virtual'); }}
                            >
                            Piloto Virtual
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'web' })}
                                onClick={() => { toggle('web'); }}
                            >
                            Web
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'vr' })}
                                onClick={() => { toggle('vr'); }}
                            >
                            Realidad Virtual
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'video' })}
                                onClick={() => { toggle('video'); }}
                            >
                            Video
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="master">
                    <Row>
                    {list.filter(card => card.tipo ==="master").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                    <CardBody>
                                        <CardTitle>{card.nombre}</CardTitle>
                                        {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                        <CardText>{card.descripcion}</CardText> 
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>
                </TabPane>
                <TabPane tabId="web">
                    <Row>
                    {list.filter(card => card.tipo ==="web").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                        <CardBody>
                                            <CardTitle>{card.nombre}</CardTitle>
                                            {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                            <CardText>{card.descripcion}</CardText> 
                                        </CardBody>
                                </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>
                </TabPane>
                <TabPane tabId="piloto-virtual">
                    <Row>
                    {list.filter(card => card.tipo ==="piloto-virtual").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                    <CardBody>
                                        <CardTitle>{card.nombre}</CardTitle>
                                        {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                        <CardText>{card.descripcion}</CardText> 
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>    
                </TabPane>    
                <TabPane tabId="tour-aereo">
                    <Row>
                    {list.filter(card => card.tipo ==="tour-aereo").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                        <CardBody>
                                            <CardTitle>{card.nombre}</CardTitle>
                                            {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                            <CardText>{card.descripcion}</CardText> 
                                        </CardBody>
                                    </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>
                </TabPane>
                <TabPane tabId="vr">
                    <Row>
                    {list.filter(card => card.tipo === "vr").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                    <CardBody>
                                        <CardTitle>{card.nombre}</CardTitle>
                                        {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                        <CardText>{card.descripcion}</CardText> 
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>
                </TabPane>
                <TabPane tabId="video">
                    <Row>
                    {list.filter(card => card.tipo === "video").map( card => (
                        <Col key={card.id} sm="6" md="4">
                            <a href={card.url} rel="noopener noreferrer" target="_blank">
                                <Card>
                                    <CardImg top width="100%" src={card.imageUrl} alt="" />
                                    <CardBody>
                                        <CardTitle>{card.nombre}</CardTitle>
                                        {/* <CardSubtitle>{card.descripcion}</CardSubtitle> */}
                                        <CardText>{card.descripcion}</CardText> 
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>
                    ))}
                    </Row>
                </TabPane>
            </TabContent>
        </Container>
    </div>
    )
}
export default SectionPortafolio