import React from 'react'
import { withFirebase } from '../Firebase'
import { DropdownItem } from 'reactstrap'

const SignOutDropdownItem = ({ firebase }) => (
    <DropdownItem
        onClick={firebase.doSignOut}
    >
        Salir
    </DropdownItem>
)
export default withFirebase(SignOutDropdownItem)