import React from "react"
import { Link } from 'react-router-dom'
import { AuthUserContext } from '../Session'
import { UncontrolledCollapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from 'reactstrap'
import SignOutDropdownItem from '../SignOut'
// core components
const NavigationAdmin = () => {
  const [bodyClick, setBodyClick] = React.useState(false);
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}

      <div className="section section-navigation p-0">
        <div id="navbar-full">
          <div className="navigation-example">
            {/* default navbar with profile photo */}
            <Navbar className="bg-info" expand="lg">
              <Container>
                <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                  LaNube360
                </NavbarBrand>
                <button
                  className="navbar-toggler navbar-toggler-right"
                  id="navbarSupportedContent3"
                  type="button"
                  onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    setBodyClick(true);
                  }}
                >
                  <span className="navbar-toggler-bar" />
                  <span className="navbar-toggler-bar" />
                  <span className="navbar-toggler-bar" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbarSupportedContent3">
                  <Nav className="ml-auto" navbar>
                    <NavItem>
                      <NavLink to='/admin/proyectos' tag={Link}>Proyectos</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to='/admin/cotizaciones' tag={Link}>Cotizaciones</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        data-toggle="dropdown"
                        height="30"
                        width="30"
                        tag={NavLink}
                      >
                        <div className="profile-photo-small">
                          <img
                            alt="..."
                            className="img-circle img-responsive img-no-padding"
                            src={require("assets/img/faces/joe-gardner-2.jpg")}
                          />
                        </div>
                      </DropdownToggle>
                      <AuthUserContext.Consumer>
                        { authUser => 
                          <DropdownMenu className="dropdown-info" right>
                            <DropdownItem>Hola {authUser.username}</DropdownItem>
                            <DropdownItem to='/admin/micuenta' tag={Link}>
                              Mi Cuenta
                            </DropdownItem>
                            <SignOutDropdownItem />
                          </DropdownMenu>
                        }
                      </AuthUserContext.Consumer>
                    </UncontrolledDropdown>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavigationAdmin;
