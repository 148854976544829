import React, { Component } from  'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'
import { Container, Col, Row, Badge, Card, CardBody, CardTitle, CardFooter, Button } from  'reactstrap'
import ModalNuevoUsuario from '../ModalNuevoUsuario'
import ModalFormProyecto from '../ModalFormProyecto'
import ButtonAvisoModal from '../ButtonAvisoModal'

import * as ROLES from  '../../constants/roles'


const Proyectos = () => {
    return(
        <AuthUserContext.Consumer>
            {authUser => (
                <div className="section section-gray pt-3" id="cards">
                    <Container className="tim-container">
                        {authUser.roles[ROLES.ADMIN] && 
                            <>
                                <ModalNuevoUsuario/>
                                <ModalFormProyecto 
                                    className="btn-round" 
                                    color="primary" 
                                    type="button" 
                                    titleForm="Registro"
                                > 
                                    Nuevo Proyecto
                                </ModalFormProyecto>
                            </>
                        }
                        <div className="title">
                            <h2>Proyectos</h2>
                        </div>
                        <ProyectoList
                            authUser={authUser}
                        />
                    </Container>
                </div>
                
            )}
        </AuthUserContext.Consumer>
    )
}

class ProyectoListBase extends Component{
    constructor(props){
        super(props)
        this.state ={
            proyectos:[],
            loading:false,
        }
    }
    fetchProyectos = () => {
        this.setState({ loading: true })
        this.unsubscribe= this.props.firebase
            .proyectos()
            .where("usuarioAsociado.uid","==", this.props.authUser.uid)
            .onSnapshot(snapshot =>{
                if(snapshot.size){
                    let proyectos = []
                    snapshot.forEach(doc => 
                    proyectos.push({ ...doc.data(), uid: doc.id })
                    )
                    this.setState({
                        proyectos: proyectos.reverse(),
                        loading:false
                    })
                } else {
                    this.setState({ proyectos: null, loading: false })
                }
            })
        
    } 
    fetchTodosProyectos = () => {
        this.setState({ loading: true })
        this.unsubscribe= this.props.firebase
            .proyectos()
            .onSnapshot(snapshot =>{
                if(snapshot.size){
                    let proyectos = []
                    snapshot.forEach(doc => 
                    proyectos.push({ ...doc.data(), uid: doc.id })
                    )
                    this.setState({
                        proyectos: proyectos.reverse(),
                        loading:false
                    })
                } else {
                    this.setState({ proyectos: null, loading: false })
                }
            })
        
    }  
    componentDidMount(){
        if(this.props.authUser.roles[ROLES.ADMIN] === "ADMIN" || this.props.authUser.roles[ROLES.EDITOR] === "EDITOR"){
            this.fetchTodosProyectos()
        }
        else{
            this.fetchProyectos()
        }
    }
    componentWillUnmount(){
        this.unsubscribe()
    }
    render(){
        const { proyectos } = this.state
        return(
            <>
                    {proyectos ? (
                        <Row >
                            {proyectos.map( proyecto => (
                                <ProyectoItem
                                    key={proyecto.uid} 
                                    proyecto={proyecto} 
                                    authUser={this.props.authUser}
                                />
                                ))
                            }
                        </Row>
                        ):(
                        <span>
                            No tienes proyectos
                        </span>
                    )}
            </>   
        )
    }
}
class ProyectoItem extends Component {
    constructor(props){
        super(props)
        this.state={}
    }
    render(){
        const { proyecto, authUser } = this.props
        return(
            <Col md="4" sm="6">
                <Card data-color="blue">
                    {/* <Link to={{pathname:`${proyecto.uid}`, state:{ proyecto }}}> */}
                        <CardBody className="text-center">
                            <CardTitle tag="h5">
                                <strong>{proyecto.nombreProyecto}</strong>
                            </CardTitle>
                            <div className="card-description">
                                {proyecto.isTitulo &&
                                    <Badge pill className="mr-1" color="info">
                                        Titulo
                                    </Badge>
                                }
                                {proyecto.isEstado &&
                                    <Badge pill className="mr-1" color="info">
                                        Estado
                                    </Badge>
                                }
                                {proyecto.numSpots &&
                                    <Badge pill className="mr-1" color="info" >
                                        <i className="nc-icon icon-pin-3 pr-1" />
                                        {proyecto.numSpots}
                                    </Badge>
                                }
                                {proyecto.isSuperficie &&
                                    <Badge pill className="mr-1" color="info">
                                        Superficie
                                    </Badge>
                                }
                                {proyecto.isValor &&
                                    <Badge pill className="mr-1" color="info">
                                        Valor
                                    </Badge>
                                }
                                {proyecto.isServidumbre &&
                                    <Badge pill className="mr-1" color="info">
                                        Servidumbre
                                    </Badge>
                                }
                                {proyecto.isCaracteristicas &&
                                    <Badge pill className="mr-1" color="info">
                                        Caracteristicas
                                    </Badge>
                                }
                            </div>
                            <CardFooter className="text-center">
                                {authUser.roles[ROLES.ADMIN] &&
                                    <ModalFormProyecto
                                        className="btn-round btn-just-icon mr-1"
                                        color="neutral"
                                        titleForm="Editar Proyecto"
                                        outline
                                        proyecto={proyecto}
                                    >
                                        <i className="nc-icon icon-d-edit" />
                                    </ModalFormProyecto>
                                }
                                {authUser.roles[ROLES.ADMIN] &&
                                    <ButtonAvisoModal 
                                        proyecto={proyecto}
                                        className="btn-round btn-just-icon mr-1" 
                                        color="neutral"
                                        outline>
                                        <i className="nc-icon icon-bin" />
                                    </ButtonAvisoModal>
                                }
                                <Button
                                    className="btn-round btn-just-icon mr-1"
                                    color="neutral"
                                    outline
                                    to={`/admin/proyectos/${proyecto.uid}`}
                                    tag={Link}
                                >
                                    <i className="nc-icon icon-eye-2" />
                                </Button>
                            </CardFooter>
                        </CardBody>
                </Card>
            </Col>
        )
    }
}
// class FormEditBase extends Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             textNombreProyecto:'',
//             textNombreEditor:'',
//             textEmailEditor:'',
//             numSpots:'',
//             isTitulo: '',
//             isEstado: '',
//             isSuperficie: '',
//             isValor: '',
//             isServidumbre: '',
//             isCaracteristicas:'',
//             loading: ''

//         }
//     }
//     componentDidMount(){
//         const { nombreProyecto, nombreEditor, emailEditor, isSuperficie, isValor, isServidumbre, isCaracteristicas, uid} = this.props.proyecto
//         this.setState({
//             uid: uid,
//             textNombreProyecto: nombreProyecto,
//             textNombreEditor: nombreEditor,
//             textEmailEditor: emailEditor,
//             isSuperficie:isSuperficie,
//             isValor:isValor,
//             isServidumbre: isServidumbre,
//             isCaracteristicas:isCaracteristicas,
//         })
//     }
//     onEditProyecto = (event) => {
//         const { textNombreProyecto, textNombreEditor, textEmailEditor, isSuperficie, isValor, isServidumbre, isCaracteristicas, uid } = this.state
//         this.props.firebase
//             .proyecto(uid)
//             .update({
//                 nombreProyecto: textNombreProyecto,
//                 nombreEditor: textNombreEditor,
//                 emailEditor: textEmailEditor,
//                 isSuperficie,
//                 isValor,
//                 isServidumbre,
//                 isCaracteristicas,
//                 createdAt: this.props.firebase.fieldValue.serverTimestamp()
//                 }
//             )
//         event.preventDefault()
//     }
//     onChangeText = event => {
//         this.setState({ [event.target.name]: event.target.value })
//     }
//     onChangeCheckbox = event => {
//         this.setState({ [event.target.name] : event.target.checked })
//     }
//     render(){
//         const {textNombreProyecto, textNombreEditor, textEmailEditor, isTitulo, isEstado, isSuperficie, isValor, isServidumbre, isCaracteristicas} = this.state
//         console.log(this.props.proyecto)
//         return(
//             <Form onSubmit={this.onEditProyecto}>
//                 <FormGroup>
//                     <label>Nombre Proyecto</label>
//                     <Input  
//                         name="textNombreProyecto" 
//                         type="text" 
//                         value={textNombreProyecto} 
//                         onChange={this.onChangeText} 
//                         placeholder='Nombre Proyecto' 
//                     />
//                 </FormGroup>
//                 <FormGroup>
//                         <label>Nombre Editor</label>
//                         <Input 
//                             name="textNombreEditor" 
//                             type="text" 
//                             value={textNombreEditor} 
//                             onChange={this.onChangeText} 
//                             placeholder='Nombre Editor' 
//                         />
//                 </FormGroup>
//                 <FormGroup>
//                         <label>Email Editor</label>
//                         <Input 
//                             name="textEmailEditor" 
//                             type="text" 
//                             value={textEmailEditor} 
//                             onChange={this.onChangeText} 
//                             placeholder='E-mail' 
//                         />
//                 </FormGroup>
//                 <FormGroup>
//                         <Input
//                             name="isTitulo"
//                             type="checkbox"
//                             checked
//                             onChange={this.onChangeCheckbox}
//                         />
//                         <label>Titulo</label>
//                 </FormGroup>
//                 <FormGroup>
//                         <Input
//                             name="isEstado"
//                             type="checkbox"
//                             checked
//                             onChange={this.onChangeCheckbox}
//                         />
//                         <label>Estado</label>
//                 </FormGroup>
//                 <FormGroup inline>
//                         <Input
//                             name="isSuperficie"
//                             type="checkbox"
//                             checked={isSuperficie}
//                             onChange={this.onChangeCheckbox}
//                         />
//                         <label>Superficie</label>
//                 </FormGroup>
//                 <FormGroup inline>
//                         <Input
//                             name="isValor"
//                             type="checkbox"
//                             checked={isValor}
//                             onChange={this.onChangeCheckbox}
//                         />
//                         <label>Valor</label>
//                 </FormGroup>
//                 <FormGroup inline>
//                         <Input
//                             name="isServidumbre"
//                             type="checkbox"
//                             checked={isServidumbre}
//                             onChange={this.onChangeCheckbox}
//                             />
//                         <label>Servidumbre</label>
//                 </FormGroup>
//                 <FormGroup inline>
//                         <Input
//                             name="isCaracteristicas"
//                             type="checkbox"
//                             checked={isCaracteristicas}
//                             onChange={this.onChangeCheckbox}
//                         />
//                         <label>Caracteristicas</label>
//                 </FormGroup>
                          
//                 <Button type='submit'>Guardar</Button>
//             </Form>
//         )
//     }
// }
const ProyectoList = withFirebase(ProyectoListBase)
// const FormEdit = withFirebase(FormEditBase)

export default Proyectos
