import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { withFirebase } from '../../components/Firebase'

import * as ROUTES from '../../constants/routes'

// reactstrap components
import { Button, Card, CardTitle, Form, Input, Container, Row, Col } from "reactstrap"

// core components
// import ColorNavbar from "components/Navbars/ColorNavbar.js";

const SignInPage = (props) => {
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  });
  return (
    <>
      {/* <ColorNavbar /> */}
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/sections/nubes.jpg") + ")"
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                <Card className="card-register">
                  <CardTitle tag="h3">Inicio sesión</CardTitle>
                  <SignInForm/>
                  {/* <div className="forgot">
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Forgot password?
                    </Button>
                  </div> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
const SignInFormBase = (props) => {
    const [loading, setLoading] = useState(false) 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const handleChangeEmail = event => {
        setEmail(event.target.value)
    }
    const handleChangePassword = event => {
        setPassword(event.target.value)
    }
    const handleSubmit = event => {
        setLoading(true)
        props.firebase.doSignInWithEmailAndPassword( email, password )
          .then( () => {
            setLoading(false)
            props.history.push(ROUTES.ADMIN)
          })
          .catch( error => {
            setLoading(false)
            setError(error)
          })
        setEmail('')
        setPassword('')
        event.preventDefault()
    }

    return(
        <Form onSubmit={ handleSubmit } className="register-form">
            <label>Email</label>
            <Input
                name="email"
                value={email}
                onChange={handleChangeEmail}
                className="no-border"
                placeholder="Email"
                type="email"
            />
            <label>Password</label>
            <Input
                name="password"
                value={password}
                onChange={handleChangePassword}
                className="no-border"
                placeholder="Password"
                type="password"
            />
            <Button type ="submit" block className="btn-round" color="danger">
                {loading && 
                    <div className="uil-reload-css reload-small mr-1">
                        <div />
                    </div>
                }
                Iniciar
            </Button>
            {error && <p>La contraseña no es válida o el usuario no tiene una contraseña</p>
            }
            
            
        </Form>
    )
}
const SignInForm = compose(withRouter,withFirebase)(SignInFormBase)
export default SignInPage;
