import React from 'react'
import { Link } from "react-router-dom"
import { Event } from '../Tracking'
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap'
import ModalMasterplan from '../ModalMasterplan'
import ModalTourAereo from '../ModalTourAereo'
import ModalPilotoVirtual from '../ModalPilotoVirtual'
const SectionServicios = () => {
    return(
        <div className="section" id="servicios">
            <Container>
                <Row>
                    <Col md="4" sm="6">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon icon-map-pin-2" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Masterplan 360º Loteos</h4>
                                <ul>
                                    <li>
                                        Herramienta de alta calidad técnica y visual para promocionar proyectos de Loteos en la web y rrss.
                                    </li>
                                    <li>
                                        Acelera procesos de comercialización y de reserva de lotes.
                                    </li>
                                    <li>
                                        Dinámica e interactiva: actualiza los precios y estados de los lotes.
                                    </li>
                                    <li>
                                        Operamos en todo Chile.
                                    </li>
                                </ul>
                                <Row className="justify-content-center">
                                    <ButtonGroup>
                                        <ModalMasterplan/>
                                        <Button
                                            to="/#portafolio"
                                            tag={Link}
                                            className="btn-link" 
                                            color="info"
                                            onClick={ () => Event("Ejemplos", "Clic button ejemplos", "Masterplan") }
                                        >
                                            Ejemplos
                                        </Button>

                                    </ButtonGroup>
                                    
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="6">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon icon-drone-2" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Tour Aéreo Inmobiliarias</h4>
                                <ul>
                                    <li>
                                        Permite visualizar el proyecto y su entorno en 360º.
                                    </li>
                                    <li>
                                        Se destacan puntos de interés y conectividad. 
                                    </li>
                                    <li>
                                        Futuras vistas para proyectos en altura.
                                    </li>
                                    <li>
                                        Herramienta para compartir por RRSS y visualizar en pantallas Touch, paginas web y mobile.
                                    </li>
                                </ul>
                                <Row className="justify-content-center">
                                    <ButtonGroup>
                                        <ModalTourAereo/>
                                        <Button
                                            to="/#portafolio"
                                            tag={Link}
                                            className="btn-link"
                                            color="info"
                                            onClick={ () => Event("Ejemplos", "Clic button ejemplos", "Tour Aéreo Inmobiliaria") }
                                        >
                                            Ejemplos
                                        </Button>
                                    </ButtonGroup>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="6">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon icon-furnished-property" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Piloto Virtual y Espacios Comunes</h4>
                                <ul>
                                    <li>
                                        Con Fotografías 360º para proyectos con piloto y Render 360º para proyectos en verde.
                                    </li>
                                    <li>
                                        Se inserta mapa de navegación y conectan vistas 360º entre si.
                                    </li>
                                    <li>
                                        Aplicación compatible con lentes VR, compartir por RRSS y visualizar en pantallas Touch, paginas web y mobile.
                                    </li>
                                </ul>
                                <Row className="justify-content-center">
                                    <ButtonGroup>
                                        <ModalPilotoVirtual/>
                                        <Button
                                            to="/#portafolio"
                                            tag={Link}
                                            className="btn-link" 
                                            color="info"
                                            onClick={ () => Event("Ejemplos", "Clic button ejemplos", "Piloto Virtual") }
                                        >
                                            Ejemplos
                                        </Button>
                                    </ButtonGroup>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="6">
                        <div className="info">
                        <div className="icon icon-info">
                            <i className="nc-icon icon-world-2" />
                        </div>
                        <div className="description">
                            <h4 className="info-title">Páginas Web</h4>
                            <ul>
                                <li>
                                    Desarrollo de paginas web en Javascript.
                                </li>
                                <li>
                                    Compatible con todos los dispositivos, diseño responsivo.
                                </li>
                                <li>
                                    Especialmente diseñadas para integrar Recorridos Virtuales.
                                </li>
                            </ul>
                            <Row className="justify-content-center">
                                <ButtonGroup>
                                    <Button
                                        to="/#portafolio"
                                        tag={Link}
                                        className="btn-link" 
                                        color="info"
                                    >
                                        Ejemplos
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </div>
                        </div>
                    </Col>
                    <Col md="4" sm="6">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon icon-vr-headset" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Realidad Virtual</h4>
                                <ul>
                                    <li>
                                        Recorridos Virtuales programados para visualizar en lentes de Realidad Virtual.
                                    </li>
                                    <li>
                                        Ideal para ferias, eventos, salas de ventas y aplicaciones mobiles.
                                    </li>
                                </ul>
                                <Row className="justify-content-center">
                                    <ButtonGroup>
                                        <Button
                                            to="/#portafolio"
                                            tag={Link}
                                            className="btn-link" 
                                            color="info"
                                        >
                                            Ejemplos
                                        </Button>
                                    </ButtonGroup>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="6">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon icon-video-player" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Video Aéreo</h4>
                                <ul>
                                    <li>
                                        Videos con drone.
                                    </li>
                                    <li>
                                        Incluye grabación y edición en alta resolución.
                                    </li>
                                    <li>
                                    Video de animaciones para proyectos inmobiliarios
                                    </li>
                                </ul>
                                <Row className="justify-content-center">
                                    <ButtonGroup>
                                        <Button
                                            to="/#portafolio"
                                            tag={Link}
                                            className="btn-link" 
                                            color="info"
                                        >
                                            Ejemplos
                                        </Button>
                                    </ButtonGroup>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default SectionServicios