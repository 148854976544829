
 const list = [
    {
        id:"1",
        tipo:"master",
        nombre:"Costa Curaco",
        descripcion:"Masterplan 360",
        url:"http://www.costacuraco.cl/",
        imageUrl:require("../../assets/img/portafolio/m1.jpg")
    },
    {
        id:"2",
        tipo:"master",
        nombre:"Punta Clara",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/punta-clara",
        imageUrl:require("../../assets/img/portafolio/m2.jpg")
    },
    {
        id:"3",
        tipo:"web",
        nombre:"Borde Angostura",
        descripcion:"Web",
        url:"https://www.lanube360.com/bordeangostura",
        imageUrl:require("../../assets/img/portafolio/borde-angostura.jpg")
    },
    {
        id:"4",
        tipo:"master",
        nombre:"Punta White",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/punta-white/",
        imageUrl:require("../../assets/img/portafolio/m3.jpg")
    },
    {
        id:"5",
        tipo:"master",
        nombre:"Reserva Frutillar",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/reserva-frutillar/",
        imageUrl:require("../../assets/img/portafolio/m4.jpg")
    },
    {
        id:"6",
        tipo:"master",
        nombre:"Refugio Lago Bertrand",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/refugio-lb/",
        imageUrl:require("../../assets/img/portafolio/m5.jpg")
    },
    {
        id:"7",
        tipo:"master",
        nombre:"Parque Lavanda",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/parque-lavanda/",
        imageUrl:require("../../assets/img/portafolio/m6.jpg")
   
    },
    {
        id:"11",
        tipo:"piloto-virtual",
        nombre:"Puerto Velero",
        descripcion:"Piloto Virtual Render 360º",
        url:"https://www.lanube360.com/puerto-velero/tour.html",
        imageUrl:require("../../assets/img/portafolio/pv1.jpg")
    },
    {
        id:"12",
        tipo:"piloto-virtual",
        nombre:"Edificio Luis Pereira",
        descripcion:"Piloto Virtual Fotografías 360º",
        url:"https://www.lanube360.com/luis-pereira/3d2b",
        imageUrl:require("../../assets/img/portafolio/pv2.jpg")
    },
    {
        id:"13",
        tipo:"piloto-virtual",
        nombre:"Bricsa Lerida",
        descripcion:"Zonas Comunes Render 360º",
        url:"https://www.lanube360.com/bricsa/espacios-comunes/",
        imageUrl:require("../../assets/img/portafolio/pv3.jpg")
    },
    {
        id:"14",
        tipo:"piloto-virtual",
        nombre:"Euroinmobiliaria Fernandez Albano",
        descripcion:"Piloto Virtual Render 360º",
        url:"https://www.lanube360.com/piloto-virtual/",
        imageUrl:require("../../assets/img/portafolio/pv4.jpg")
    },
    {
        id:"15",
        tipo:"web",
        nombre:"Puelo Virgen",
        descripcion:"Web",
        url:"https://www.lanube360.com/puelo-virgen/",
        imageUrl:require("../../assets/img/portafolio/puelo-virgen.jpg")
    },
    {
        id:"17",
        tipo:"web",
        nombre:"El Pozon",
        descripcion:"Página web",
        url:"http://www.elpozon.cl/",
        imageUrl:require("../../assets/img/portafolio/pw1.jpg")
    },
    {
        id:"18",
        tipo:"web",
        nombre:"Parque Santa Otilia",
        descripcion:"Página web",
        url:"http://www.parquesantaotilia.cl/",
        imageUrl:require("../../assets/img/portafolio/pw2.jpg")
    },
    {
        id:"19",
        tipo:"web",
        nombre:"Bosques de La Patagonia",
        descripcion:"Página web",
        url:"http://www.bosquesdelapatagonia.cl/",
        imageUrl:require("../../assets/img/portafolio/pw3.jpg")
    },
    {
        id:"20",
        tipo:"web",
        nombre:"La Reserva de Marbella",
        descripcion:"Página web",
        url:"https://www.lareservademarbella.cl/",
        imageUrl:require("../../assets/img/portafolio/pw4.jpg")
    },
    {
        id:"21",
        tipo:"web",
        nombre:"Rios de la Patagonia",
        descripcion:"Página web",
        url:"http://www.riosdelapatagonia.cl/",
        imageUrl:require("../../assets/img/portafolio/pw5.jpg")
    },
    {
        id:"22",
        tipo:"web",
        nombre:"Fiordos de Puyuhuapi",
        descripcion:"Página web",
        url:"http://www.fiordosdepuyuhuapi.cl/",
        imageUrl:require("../../assets/img/portafolio/pw6.jpg")
    },
    {
        id:"23",
        tipo:"web",
        nombre:"Mirador de Pelluco",
        descripcion:"Página web",
        url:"http://www.miradordepelluco.cl/",
        imageUrl:require("../../assets/img/portafolio/pw7.jpg")
    },
    {
        id:"24",
        tipo:"master",
        nombre:"Vista Volcán",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/vista-volcan/",
        imageUrl:require("../../assets/img/portafolio/m7.jpg")
    },
    {
        id:"25",
        tipo:"master",
        nombre:"Valle La Launa",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/valle-la-launa/valle.html",
        imageUrl:require("../../assets/img/portafolio/m8.jpg")
    },
    {
        id:"26",
        tipo:"master",
        nombre:"Hacienda Huincul",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/hacienda-huincul/",
        imageUrl:require("../../assets/img/portafolio/m9.jpg")
    },
    {
        id:"27",
        tipo:"master",
        nombre:"Reserva Santa Clara",
        descripcion:"Masterplan 360",
        url:"https://reservasantaclara.cl/masterplan/",
        imageUrl:require("../../assets/img/portafolio/m10.jpg")
    },
    {
        id:"28",
        tipo:"master",
        nombre:"Mesetas Changanal",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/mesetas-changanal/",
        imageUrl:require("../../assets/img/portafolio/m11.jpg")
    },
    {
        id:"29",
        tipo:"master",
        nombre:"Rio Futa",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/tour-riofuta/",
        imageUrl:require("../../assets/img/portafolio/m12.jpg")
    },
    {
        id:"30",
        tipo:"master",
        nombre:"Chaguay",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/chaguay/",
        imageUrl:require("../../assets/img/portafolio/m13.jpg")
    },
    {
        id:"31",
        tipo:"master",
        nombre:"Olivos Cachagua",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/olivos-cachagua",
        imageUrl:require("../../assets/img/portafolio/m14.jpg")
    },
    {
        id:"32",
        tipo:"master",
        nombre:"Ochoquebradas",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/ochoquebradas/",
        imageUrl:require("../../assets/img/portafolio/m15.jpg")
    },
    {
        id:"33",
        tipo:"master",
        nombre:"Riveras Chamiza",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/riveras-chamiza/",
        imageUrl:require("../../assets/img/portafolio/m16.jpg")
    },
    {
        id:"34",
        tipo:"master",
        nombre:"Reserva los Boldos",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/reserva-boldos/",
        imageUrl:require("../../assets/img/portafolio/m17.jpg")
    },
    {
        id:"35",
        tipo:"master",
        nombre:"Chepu",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/chepu-tour/",
        imageUrl:require("../../assets/img/portafolio/m18.jpg")
    },
    {
        id:"36",
        tipo:"master",
        nombre:"Rapalo",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/rapalo/",
        imageUrl:require("../../assets/img/portafolio/m19.jpg")
    },
    {
        id:"37",
        tipo:"master",
        nombre:"Quincheumo",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/quincheumo/",
        imageUrl:require("../../assets/img/portafolio/m20.jpg")
    },
    {
        id:"38",
        tipo:"master",
        nombre:"Quebrada Putique",
        descripcion:"Masterplan 360",
        url:"http://www.quebradaputique.cl/",
        imageUrl:require("../../assets/img/portafolio/m21.jpg")
    },
    {
        id:"39",
        tipo:"master",
        nombre:"Puerto Klocker",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/puerto-clocker/",
        imageUrl:require("../../assets/img/portafolio/m22.jpg")
    },
    {
        id:"40",
        tipo:"master",
        nombre:"Refugio Pescadores",
        descripcion:"Masterplan 360",
        url:"http://www.refugiodepescadores.cl/",
        imageUrl:require("../../assets/img/portafolio/m23.jpg")
    },
    {
        id:"41",
        tipo:"master",
        nombre:"Patagonia Virgin",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/patagonia-virgin/",
        imageUrl:require("../../assets/img/portafolio/m24.jpg")
    },
    {
        id:"42",
        tipo:"master",
        nombre:"Mas de Sales",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/parsimonia",
        imageUrl:require("../../assets/img/portafolio/m25.jpg")
    },
    {
        id:"43",
        tipo:"master",
        nombre:"Parque Pudú",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/parque-pudu/",
        imageUrl:require("../../assets/img/portafolio/m26.jpg")
    },
    {
        id:"44",
        tipo:"master",
        nombre:"Parcelas Lago Chapo",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/parcelas-chile",
        imageUrl:require("../../assets/img/portafolio/m27.jpg")
    },
    {
        id:"45",
        tipo:"master",
        nombre:"Mercedes Casablanca",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/mercedes/",
        imageUrl:require("../../assets/img/portafolio/m28.jpg")
    },
    {
        id:"46",
        tipo:"master",
        nombre:"Maucolen",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/maucolen",
        imageUrl:require("../../assets/img/portafolio/m29.jpg")
    },
    {
        id:"47",
        tipo:"master",
        nombre:"Hacienda Chacao",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/hacienda-chacao",
        imageUrl:require("../../assets/img/portafolio/m30.jpg")
    },
    {
        id:"48",
        tipo:"master",
        nombre:"Parque El Canelo",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/ground-chile/parque-el-canelo/",
        imageUrl:require("../../assets/img/portafolio/m31.jpg")
    },
    {
        id:"49",
        tipo:"master",
        nombre:"Condominio Maitenes",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/condominio-maitenes/",
        imageUrl:require("../../assets/img/portafolio/m32.jpg")
    },
    {
        id:"50",
        tipo:"master",
        nombre:"Casas Pinar",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/casas-pinar/",
        imageUrl:require("../../assets/img/portafolio/m33.jpg")
    },
    {
        id:"51",
        tipo:"master",
        nombre:"Altos de Valdivia",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/altovaldivia360/",
        imageUrl:require("../../assets/img/portafolio/m34.jpg")
    },
    {
        id:"52",
        tipo:"master",
        nombre:"Acantilados del Alto",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/acantilados/",
        imageUrl:require("../../assets/img/portafolio/m35.jpg")
    },
    {
        id:"53",
        tipo:"master",
        nombre:"Altos Mehuin",
        descripcion:"Masterplan 360",
        url:"https://www.lanube360.com/altos-mehuin/",
        imageUrl:require("../../assets/img/portafolio/m36.jpg")
    },
    {
        id:"54",
        tipo:"tour-aereo",
        nombre:"Infinity Miramar",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/infinity/",
        imageUrl:require("../../assets/img/portafolio/ta1.jpg")
    },
    {
        id:"55",
        tipo:"tour-aereo",
        nombre:"Vista Real 360º Bersa NK",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/bersa-nk/",
        imageUrl:require("../../assets/img/portafolio/ta2.jpg")
    },
    {
        id:"56",
        tipo:"tour-aereo",
        nombre:"Santa Elena de Chicureo",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/santa-elena/",
        imageUrl:require("../../assets/img/portafolio/ta3.jpg")
    },
    {
        id:"57",
        tipo:"tour-aereo",
        nombre:"Ñuñoa Vita Norte Verde",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/norte-verde/nunoa-vita/",
        imageUrl:require("../../assets/img/portafolio/ta4.jpg")
    },
    {
        id:"58",
        tipo:"tour-aereo",
        nombre:"Alto Sacramento Sinergia",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/sinergia/altos-sacramentos/",
        imageUrl:require("../../assets/img/portafolio/ta5.jpg")
    },
    {
        id:"59",
        tipo:"tour-aereo",
        nombre:"Parque Portales Norte Verde",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/norte-verde/parque-portales/",
        imageUrl:require("../../assets/img/portafolio/ta6.jpg")
    },
    {
        id:"60",
        tipo:"tour-aereo",
        nombre:"Vista Portezuelo",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/tour-vista-portezuelo/",
        imageUrl:require("../../assets/img/portafolio/ta7.jpg")
    },
    {
        id:"61",
        tipo:"tour-aereo",
        nombre:"Vista Real 360º Vilanova 600",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/vilanova600/",
        imageUrl:require("../../assets/img/portafolio/ta8.jpg")
    },
    {
        id:"62",
        tipo:"tour-aereo",
        nombre:"Edificio Portugal CBS",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/portugal/",
        imageUrl:require("../../assets/img/portafolio/ta9.jpg")
    },
    {
        id:"63",
        tipo:"tour-aereo",
        nombre:"Vista Higuerillas Australis",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/vista-higuerillas4/",
        imageUrl:require("../../assets/img/portafolio/ta10.jpg")
    },
    {
        id:"64",
        tipo:"tour-aereo",
        nombre:"Mirador Boulevart Concon",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/mirador-boulevart/",
        imageUrl:require("../../assets/img/portafolio/ta11.jpg")
    },
    {
        id:"65",
        tipo:"tour-aereo",
        nombre:"Mirador Bahia Coquimbo",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/mirador-bahia/",
        imageUrl:require("../../assets/img/portafolio/ta12.jpg")
    },
    {
        id:"66",
        tipo:"tour-aereo",
        nombre:"Lomas Coyhaique Icuadra",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/lomas-coyhaique/",
        imageUrl:require("../../assets/img/portafolio/ta13.jpg")
    },
    {
        id:"67",
        tipo:"tour-aereo",
        nombre:"Parque Lauquen Icuadra",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/icuadra/lauquen2/tour.html",
        imageUrl:require("../../assets/img/portafolio/ta14.jpg")
    },
    {
        id:"8",
        tipo:"tour-aereo",
        nombre:"Eliodoromix Siena",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/eliodoromix",
        imageUrl:require("../../assets/img/portafolio/ta15.jpg")
    },
    {
        id:"68",
        tipo:"tour-aereo",
        nombre:"Vista Real 360º Pocuro MPC",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/icuadra/lauquen2/tour.html",
        imageUrl:require("../../assets/img/portafolio/ta16.jpg")
    },
    {
        id:"9",
        tipo:"tour-aereo",
        nombre:"Gran Marina Euroinmobiliaria",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/gran-marina",
        imageUrl:require("../../assets/img/portafolio/ta17.jpg")
    },
    {
        id:"69",
        tipo:"tour-aereo",
        nombre:"Edificio 27 Puerto Velero",
        descripcion:"Tour Aéreo Inmobiliaria",
        url:"https://www.lanube360.com/edificio27/",
        imageUrl:require("../../assets/img/portafolio/ta18.jpg")
    },
    {
        id:"70",
        tipo:"vr",
        nombre:"Viña Errazuriz",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/vina-errazuriz/tour.html",
        imageUrl:require("../../assets/img/portafolio/rv1.jpg")
    },
    {
        id:"71",
        tipo:"vr",
        nombre:"Filmcommission ProChile",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/filmcommission/",
        imageUrl:require("../../assets/img/portafolio/rv2.jpg")
    },
    {
        id:"72",
        tipo:"vr",
        nombre:"Patagon Land Parcelas VR",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/parcelas-vr/",
        imageUrl:require("../../assets/img/portafolio/rv3.jpg")
    },
    {
        id:"73",
        tipo:"vr",
        nombre:"Novo Seminario",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/novo-seminario/",
        imageUrl:require("../../assets/img/portafolio/rv4.jpg")
    },
    {
        id:"74",
        tipo:"vr",
        nombre:"Inmobiliaria Aconcagua",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/lds-aconcagua/",
        imageUrl:require("../../assets/img/portafolio/rv5.jpg")
    },
    {
        id:"75",
        tipo:"vr",
        nombre:"Euroinmobiliaria",
        descripcion:"Realidad Virtual",
        url:"https://www.lanube360.com/san-nicolas/tour.html",
        imageUrl:require("../../assets/img/portafolio/rv6.jpg")
    },
    {
        id:"76",
        tipo:"video",
        nombre:"Carpintero Negro Puerto Varas",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=S6Ix0nlA5Fo",
        imageUrl:require("../../assets/img/portafolio/vi1.jpg")
    },
    {
        id:"77",
        tipo:"video",
        nombre:"Quebrada Putique Achao",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=s_u-MY2c-34",
        imageUrl:require("../../assets/img/portafolio/vi2.jpg")
    },
    {
        id:"78",
        tipo:"video",
        nombre:"Punta White Laitec",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=CggJ4x-s1Ls",
        imageUrl:require("../../assets/img/portafolio/vi3.jpg")
    },
    {
        id:"79",
        tipo:"video",
        nombre:"Puerto Guarda Lago Ranco",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=iDkEaOwgUmk",
        imageUrl:require("../../assets/img/portafolio/vi4.jpg")
    },
    {
        id:"80",
        tipo:"video",
        nombre:"Hacienda Casablanca",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=icBOJWzUiFo",
        imageUrl:require("../../assets/img/portafolio/vi5.jpg")
    },
    {
        id:"81",
        tipo:"video",
        nombre:"El Pozon Río Puelo",
        descripcion:"Video Aéreo",
        url:"https://www.youtube.com/watch?v=02PbFsD7FXU",
        imageUrl:require("../../assets/img/portafolio/vi6.jpg")
    },
    {
        id:"82",
        tipo:"piloto-virtual",
        nombre:"Siena Pontevedra",
        descripcion:"Zonas Comunes Fotografias 360º",
        url:"https://www.lanube360.com/siena/parque-pontevedra/",
        imageUrl:require("../../assets/img/portafolio/pv5.jpg")
    },
    {
        id:"83",
        tipo:"piloto-virtual",
        nombre:"Avsa Incity",
        descripcion:"Piloto Virtual Depto Render 360º",
        url:"https://www.lanube360.com/incity/",
        imageUrl:require("../../assets/img/portafolio/pv6.jpg")
    },
    {
        id:"84",
        tipo:"piloto-virtual",
        nombre:"Siena Canquen",
        descripcion:"Piloto Virtual Casa Fotografías 360º",
        url:"https://www.lanube360.com/siena/canquen",
        imageUrl:require("../../assets/img/portafolio/pv7.jpg")
    },
    {
        id:"85",
        tipo:"piloto-virtual",
        nombre:"Bricsa San Francisco",
        descripcion:"Piloto Virtual Depto Render 360º",
        url:"https://www.lanube360.com/bricsa/c3-final/",
        imageUrl:require("../../assets/img/portafolio/pv8.jpg")
    },
    {
        id:"86",
        tipo:"piloto-virtual",
        nombre:"Edificio Luis Pereira",
        descripcion:"Piloto Virtual Fotografias 360º",
        url:"https://www.lanube360.com/luis-pereira/2d2b/",
        imageUrl:require("../../assets/img/portafolio/pv9.jpg")
    },
    {
        id:"87",
        tipo:"piloto-virtual",
        nombre:"Siena Parque Gauss",
        descripcion:"Zonas comunes Fotografías 360º",
        url:"https://www.lanube360.com/siena/parque-gauss/",
        imageUrl:require("../../assets/img/portafolio/pv10.jpg")
    },
    {
        id:"88",
        tipo:"piloto-virtual",
        nombre:"Euroinmobiliaria Lazo",
        descripcion:"Piloto Virtual Depto Render 360º",
        url:"https://www.lanube360.com/lazo-tour/tour.html",
        imageUrl:require("../../assets/img/portafolio/pv11.jpg")
    },
    {
        id:"89",
        tipo:"piloto-virtual",
        nombre:"Alto Manzano",
        descripcion:"Piloto Virtual Casa Render 360º",
        url:"https://www.lanube360.com/alto-manzano/",
        imageUrl:require("../../assets/img/portafolio/pv12.jpg")
    },
    {
        id:"90",
        tipo:"piloto-virtual",
        nombre:"Bricsa",
        descripcion:"Piloto Virtual Casa Render 360º",
        url:"https://www.lanube360.com/bricsa/casa-chicauma/",
        imageUrl:require("../../assets/img/portafolio/pv13.jpg")
    },
    {
        id:"91",
        tipo:"piloto-virtual",
        nombre:"Novo Seminario",
        descripcion:"Zonas comunes Render 360º",
        url:"https://www.lanube360.com/novo-espacios-comunes/tour.html",
        imageUrl:require("../../assets/img/portafolio/pv14.jpg")
    },
    {
        id:"92",
        tipo:"piloto-virtual",
        nombre:"Sinergia Casa Taiquen 140",
        descripcion:"Piloto Virtual Casa Fotografias 360º",
        url:"https://www.lanube360.com/sinergia/taiquen/140/",
        imageUrl:require("../../assets/img/portafolio/pv15.jpg")
    },
    {
        id:"93",
        tipo:"piloto-virtual",
        nombre:"Siena Chicureo",
        descripcion:"Piloto Virtual Casa Fotografias 360º",
        url:"https://www.lanube360.com/siena/chicureo/",
        imageUrl:require("../../assets/img/portafolio/pv16.jpg")
    }
]
export default list