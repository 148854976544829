import React from 'react'
import { Event } from '../Tracking'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from "react-router-dom"
const SectionNosotros = () => (
    <div className="section text-center" id="nosotros">
        <Container>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h3 className="title text-justify">Nosotros</h3>
                    <h5 className="description text-left">
                        La nube 360 es una agencia de comunicación digital con 10 años de trayectoria y más de 1.200 proyectos ejecutados.
                        <br/><br/>
                        Nos hemos especializado en Recorridos Virtuales avanzados, estamos constantemente desarrollando herramientas 
                        innovadoras de alta calidad técnica y visual siendo pioneros en el mercado.<br/><br/>
                        Utilizamos las nuevas tecnologías de información y comunicación con el fin de darle valor agregado a las estrategias 
                        de marketing digital y acelerar los procesos de comercialización.<br/><br/>
                        Trabajamos en todo Chile y estamos asociados con Pilotos de Drone de todo America lo que nos permite rápidos tiempos 
                        de ejecución.
                    </h5>
                    <br />
                    <Button
                        className="btn-round"
                        color="info"
                        to="/#servicios"
                        tag={Link}
                        onClick={() => Event('Nosotros', 'clic ver servicios', 'servicios')}
                    >
                        Servicios
                    </Button>
                </Col>
            </Row> 
        </Container>
    </div>
)
export default SectionNosotros